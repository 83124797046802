




















































































































import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';

@Component({
  components: { FontAwesomeComponent, ButtonIconComponent },
})
export default class CompanyContactCard extends VueBaseWidget {
  @Prop({ default: '' })
  private readonly name!: string;

  @Prop({ default: '' })
  private type!: string;

  @Prop({ default: '' })
  private email!: string;

  @Prop({ default: '' })
  private phone!: string;

  @Prop({ default: '' })
  private fax!: string;

  private sendEmail = (email: string): void => {
    window.open(`mailto:${email}`);
  }

  private phoneCall = (phone: string): void => {
    window.open(`tel:${phone}`);
  }

  private faxCall = (fax: string): void => {
    window.open(`tel:${fax}`);
  }
}
